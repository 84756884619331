<template>
    <section class="login">
        <div class="login-photo">
        </div>
        <div class="login-wrap">
            <router-link to="/" class="login-logo">
                <img src="../../assets/images/logo.svg" alt="logo"/>
            </router-link>
            <div class="remind-password">
                <RemindPasswordForm @succeed='succeedRemind' v-if="!token" />
                <ResetSms v-if="state ==  2" @succeed='succeedSms' :token="token"/>
                <NewPassword v-if="state ==  3" :token="token"/>
            </div>
        </div>
    </section>
</template>

<script>
import CustomButton from "./../../components/CustomButton";
import AuthService from "./../../services/AuthService";
import ResetSms from './../../components/ResetSms';
import NewPassword from './../../components/NewPassword';
import RemindPasswordForm from './../../components/RemindPasswordForm';

export default {
    name: "RemindPassword",
    data() {
        return {
            token: '',
            state: 1,
            errors: {
                card_id: '',
            },
            form: {
                card_id: "",
            }
        };
    },
    components: {
        CustomButton,
        ResetSms,
        NewPassword,
        RemindPasswordForm,
    },
    methods: {
        succeedRemind(token){
            this.token = token;
            this.state = 2;
        },
        succeedSms(token){
            this.token = token;
            this.state = 3;
        },
        newPassword(token) {
            this.token = token;
        }
    },
};
</script>

<style scoped lang="less">
.login {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #1a2744;

    &-wrap {
        position: relative;
        max-width: 740px;
        width: 100%;
        height: 100vh;
        background: #1a2744;
        padding: 80px 100px 100px 100px;
        display: flex;
        flex-direction: column;
    }
}

.login-photo {
    background: #1a2744 url(../../assets/images/city.jpg) center left no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
}

.login-photo::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #1a2744;
    opacity: 0.4;
}

.form-title {
    font-weight: bold;
    font-size: 46px;
    line-height: 60px;
    letter-spacing: -0.322px;
    color: #fff;
    margin-bottom: 80px;
}

.login-form__actions {
    display: flex;
    align-items: center;
    justify-content: center;
}


.restore {
    margin-bottom: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-label {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
    }

    &-link {
        color: #ffc900;
        margin-left: 8px;
    }
}


.show-password {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}

.info-ic {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.btn-register {
    height: 72px;
    width: 240px;
    background: #f5f5f5;
    background: url(../../assets/images/btn-register.png) no-repeat center center;
    border-radius: 17px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #1a2744;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.btn-register:focus {
    transform: scale(1.04);
}

.btn-login {
    height: 72px;
    width: 240px;
    background: #ffc900;
    background: url(../../assets/images/btn-login.png) no-repeat center center;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
}

.btn-login:focus {
    transform: scale(1.04);
}

.login-slider {
    width: 400px;
    margin-left: auto;
    margin-right: 112px;
    transition: all 0.2s !important;
}

.login-slider .is-active {
    background-image: url(../../assets/images/card-active.svg) !important;
}


.slide {
    width: 400px;
    background-image: url(../../assets/images/card.svg);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 100px 83px 98px 83px;
    text-align: center;
    outline: none !important;
}

.card-img {
    height: 241px;
    width: 166px;
    margin: 0 auto 59px auto;
}

.card-title {
    max-width: 234px;
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.224px;
    color: #ffffff;
}

@media screen and (max-width: 1440px) {
    .login-wrap {
        max-width: 600px;
        padding: 40px 60px 40px 60px;
    }
}

@media screen and (max-height: 850px) {
    .login-wrap {
        max-width: 600px;
        padding: 40px 40px 40px;
    }

    .form-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}

@media screen and (max-height: 700px) {
    .slide {
        height: 534px !important;
        padding: 67px 63px 73px 63px;
        background-size: contain;
    }
}
</style>


<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    color: #FFFFFF;
    margin-bottom: 84px;
}

.form-control {
    position: relative;
     margin-bottom: 42px;

    .error-msg {
        position: absolute;
        top: 100%;
        right: 0;
        color: #ffc900;
        font-size: 16px;
        text-align: right;
    }
 }


.remind-password_input {
    height: 56px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid #FFC900;
    background: transparent;
    padding-left: 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #F5F5F5;
    outline: none;

    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none;
    &::placeholder {
        color: #fff;
    }
}

.remind-password {
    margin-top: auto;

    &_img {
        margin: 0 auto 40px auto;
        width: 230px;
        height: 302px;
    }
}

.custom-button {
    margin: 0 auto;
    display: block;
}

@media screen and (max-width: 992px)  {
    .login-wrap {
        margin: 0 auto;
        max-width: 100%;
    }
    .login-photo {
        display: none;
    }
}

@media screen and (max-height: 850px) {
    .login-wrap {
        padding: 70px 40px 40px;
    }
    .title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
    .login-logo {
        margin-bottom: 30px;
    }
    .login-form__input {
        height: 46px;
    }
    .login-form .form-control {
        margin-bottom: 30px;
    }
}
@media screen and (max-height: 700px) {
    .login-wrap {
        padding: 40px;
        overflow-y: auto;
    }
    .title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .login-form .form-control {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 35px;
        line-height: 45px;
    }
    .login {
        overflow-y: auto;
     }
    .login-wrap {
        height: 100%;
    }

}

@media screen and (max-width: 480px) {
    .login-logo {
        height: 40px;
        min-height: 40px;
        width: 62px;
        overflow: hidden;
        margin-bottom: 74px;
        img {
            height: 100%;
            width: auto;
        }
    }
    .title {
        font-size: 27px;
        line-height: 37px;
        margin-bottom: 80px;
    }
    .login-photo {
        display: none;
    }
    .login-wrap {
        max-width: 100%;
        width: 100%;
        height: auto;
        padding: 40px 20px 60px;
    }
    .remind-password_input {
        height: 46px;
    }
    .remind-password_img {
        display: none;
    }
    .custom-button {
        line-height: 1.2;
        font-size: 24px;
        width: 240px;
        height: 52px;
        &:after {
            top: 5%;
            margin-left: -78px;
            width: 149px;
            height: 50px;
        }
  }
  .remind-password {
    margin-top: 0;
  }
}



</style>

