<template>
   <div class="reset-password">
        <form @submit.prevent="submitForm">
            <input type="hidden" name="token" :value="token">
            <h3 class="title">Створення пароля</h3>
            <div class="inputs-group">
                <input
                    id="password"
                    name="password"
                    type="password"
                    v-model="form.password"
                    placeholder="Ваш пароль"
                >
               <span class="error-msg" v-if="errors.password">{{errors.password}}</span>
            </div>
            <div class="inputs-group">
                <input
                    id="password_repeat"
                    name="password_repeat"
                    type="password"
                    v-model="form.password_repeat"
                    placeholder="Повторити пароль"
                >
                <span class="error-msg" v-if="errors.password_repeat">{{errors.password_repeat}}</span>
            </div>
             <CustomButton label="Підтвердити"/>
        </form>
   </div>
</template>

<script>
import CustomButton from "@/components/CustomButton";
import AuthService from "@/services/AuthService";

export default {
    name: "SmsCodeForm",
    props: {
        token: {
            type: String,
            require: true
        }
    },
    components: {
        CustomButton,
    },
    data() {
        return {
            password_confirmation: null,
            errors: {
                password: "",
                password_repeat: ''
            },
            form: {
                password: "",
                password_repeat: ''
            }
        };
    },
    methods: {
        async submitForm(e) {
            let form = new FormData();
            form.append('password', this.form.password);
            form.append('password_repeat', this.form.password_repeat);
            form.append('token', this.token);

            let resp = await AuthService.newPassword(form).catch(this.catchHandler);
            if(resp) {
                location.href = '/login'
                // this.$router.push('/login')
            }
        },
        catchHandler(resp) {
            if(resp.statusCode === 422) {
                this.errors = resp.data;
            }
        },
    }
}
</script>


<style lang="less" scoped>
    .title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        color: #FFFFFF;
        margin-bottom: 89px;
    }
    .error-msg {
        position: absolute;
        top: 100%;
        right: 0;
        color: #ffc900;
        font-size: 16px;
        text-align: right;
    }
    .sms-code {
        margin-top: auto;
    }
    .sms-code_img {
        width: 225px;
        height: 297px;
        margin: 0 auto 40px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .inputs-group {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 42px;
        input {
            width: 100%;
            height: 56px;
            border: 1px solid #ffc900;
            background: transparent;
            border-radius: 15px;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #f5f5f5;
            padding-left: 20px;
            outline: none;
            transition: all 0.3s ease-in;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &::placeholder {
                color: #f5f5f5;
            }

            &:focus::placeholder {
                color: #1a2744;
            }
            &:focus {
                background: #FFC900;
                box-shadow: 0 24px 24px rgba(249, 198, 35, 0.35);
                color: #1A2744;
            }
        }
        .error-msg {
            position: absolute;
            top: 100%;
            right: 0;
            color: #ffc900;
            font-size: 16px;
            text-align: right;
        }
    }
    .custom-button {
        margin: 0 auto;
        display: block;
    }


     @media all and (max-width: 1440px)  {
        .custom-button {
            line-height: 1.2;
            font-size: 24px;
            width: 240px;
            height: 52px;
            &:after {
                top: 5%;
                margin-left: -78px;
                width: 149px;
                height: 50px;
            }
        }
     }


    @media all and (max-height: 900px) {
        .title {
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 40px;
        }
    }
    @media all and (max-height: 800px) {
        .sms-code_img {
            width: 180px;
            height: 200px;
        }
        .inputs-group {
            margin-bottom: 30px;
            input {
                height: 46px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .login {
            height: 100%;
        }
         .login-wrap {
            height: 100%;
        }
    }

    @media all and (max-width: 480px) {
        .sms-code_img {
            display: none;
        }
        .sms-code {
            margin-top: 0;
        }
        .title {
            margin-bottom: 80px;
                font-size: 30px;
                line-height: 40px;
        }
        .inputs-group {
            margin-bottom: 45px;
        }
        .inputs-group input {
            height: 46px;
        }
    }
</style>
