<template>
    <form  @submit.prevent="resetPassword">
        <h3 class="title">Нагадати пароль</h3>
            <div class="remind-password_img">
                <img src="../assets/images/remind.svg" alt="">
            </div>

            <div class="form-control">
                <input
                    class="remind-password_input"
                    type="text"
                    ref="card_id"
                    placeholder="Номер партійного квитка"
                    name="card_id"
                    v-model="form.card_id"
                >
            <span class="error-msg" v-if="errors.card_id">{{errors.card_id}}</span>
             </div>
            <CustomButton label="Підтвердити"/>
    </form>
</template>

<script>
import CustomButton from "@/components/CustomButton";
import AuthService from "@/services/AuthService";

export default {
    data() {
        return {
            token: '',
            errors: {
                card_id: '',
            },
            form: {
                card_id: "",
            }
        };
    },
    async created() {
        await this.$recaptchaLoaded();
    },
    components: {
        CustomButton,
    },
    methods: {
        async resetPassword(e) {

            let form = new FormData();

            form.append('card_id', this.form.card_id);

            this.recaptcha = await this.$recaptcha('ResetPassword');
            form.append('recaptcha', this.recaptcha);

            let resp = await AuthService.resetPassword(form).catch(this.catchHandler);

            // let resp = await AuthService.resetPassword(new FormData(e.target)).catch(this.catchHandler);
            if(resp && resp.token) {
                this.$emit('succeed', resp.token)
            }
        },
        catchHandler(resp) {
            if(resp.statusCode === 422) {
                this.errors = resp.data;
            }
        },
    }
}
</script>

<style lang="less" scoped>
    .title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        color: #FFFFFF;
        margin-bottom: 84px;
    }
    .form-control {
        position: relative;
         margin-bottom: 42px;
        .error-msg {
            position: absolute;
            top: 100%;
            right: 0;
            color: #ffc900;
            font-size: 16px;
            text-align: right;
        }
    }
    .remind-password_input {
        height: 56px;
        width: 100%;
        border-radius: 15px;
        border: 1px solid #FFC900;
        background: transparent;
        padding-left: 20px;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #F5F5F5;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::placeholder {
            color: #fff;
        }
    }

.remind-password {
    margin-top: auto;

    &_img {
        margin: 0 auto 40px auto;
        width: 230px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.custom-button {
    margin: 0 auto;
    display: block;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

@media screen and (max-height: 850px) {
      .login-wrap {
        max-width: 600px;
        padding: 70px 50px 40px;
    }
    .title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
    .login-logo {
        margin-bottom: 30px;
    }
    .remind-password_img {
        height: 170px;
        margin-bottom: 20px;
    }
}
@media screen and (max-height: 700px) {
    .login-wrap {
        padding: 40px;
        overflow-y: auto;
    }
    .title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 768px) {
    .title {
        font-size: 35px;
        line-height: 45px;
    }
}


@media screen and (max-width: 480px) {
    .title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 80px;
    }
    .remind-password_input {
        height: 46px;
    }
    .remind-password_img {
        display: none;
    }
    .custom-button {
        line-height: 1.2;
        font-size: 24px;
        width: 240px;
        height: 52px;
        &:after {
            top: 5%;
            margin-left: -78px;
            width: 149px;
            height: 50px;
    }
  }
  .remind-password {
    margin-top: 0;
  }
}



</style>
